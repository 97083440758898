import React, { FunctionComponent } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import { DaznTheme } from '../../muiTheme';
import { DaznLink } from '../../api/interfaces';
import Button from '@material-ui/core/Button';
import { QrModal } from './QrModal';
import { CreatedBy } from './CreatedBy';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import IconQrCode from './IconQrCode';

interface Props {
    link: DaznLink;
    setLinkCopied: (copied: boolean) => void;
    linkCopied: boolean;
}

const useStyles = makeStyles((theme: DaznTheme) =>
    createStyles({
        daznLinkColumn: {
            width: '30%',
            wordBreak: 'break-all',
        },
        redirectToColumn: {
            wordBreak: 'break-word',
            width: '35%',
        },
        createdByColumn: {
            wordBreak: 'break-word',
            width: '25%',
        },
        qrColumn: {
            wordBreak: 'break-word',
            width: '10%',
        },
        linkPath: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        linkRedirectTo: {
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        tooltipLink: {
            color: theme.dazn.colors.yellow.primary,
            fontWeight: 'bold',
            margin: theme.spacing(1, 1, 1, 0),
        },
        materialButton: {
            minWidth: '0',
        },
        copyLinkButtonIcon: {
            width: '0.8em',
            height: '0.8em',
        },
        qrCodeButtonIcon: {
            width: '1.2em',
            height: '1.2em',
        },
    })
);

const DaznTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: theme.shadows[1],
        fontSize: '0.75rem',
        padding: theme.spacing(),
    },
}))(Tooltip);

const Row: FunctionComponent<Props> = ({
    link: {
        attributes: { path, url, authorEmail, authorAvatar, visits },
    },
    setLinkCopied,
    linkCopied,
}: Props) => {
    const styles = useStyles();

    const [tooltipVisible, setTooltipVisible] = React.useState(false);
    const [qrModalVisible, setQrModalVisible] = React.useState(false);

    return (
        <TableRow hover>
            <TableCell
                className={styles.daznLinkColumn}
                component="th"
                scope="row"
            >
                <Link
                    href={`https://${process.env.REACT_APP_REDIRECT_HOST}/${path}`}
                    target="_blank"
                    rel="noopener"
                    className={styles.linkPath}
                    onMouseEnter={() => setTooltipVisible(true)}
                    onMouseLeave={() => {
                        setTooltipVisible(false);
                        setLinkCopied(false);
                    }}
                >
                    /{decodeURI(path)}
                </Link>
            </TableCell>
            <TableCell className={styles.redirectToColumn}>
                <Link
                    href={url}
                    target="_blank"
                    rel="noopener"
                    color="secondary"
                    className={styles.linkRedirectTo}
                    onMouseEnter={() => setTooltipVisible(true)}
                    onMouseLeave={() => {
                        setTooltipVisible(false);
                        setLinkCopied(false);
                    }}
                >
                    {url}
                </Link>
            </TableCell>
            <TableCell className={styles.createdByColumn}>
                <CreatedBy
                    authorEmail={authorEmail}
                    authorAvatar={authorAvatar}
                />
            </TableCell>
            <TableCell className={styles.qrColumn}>
                <DaznTooltip
                    title={
                        linkCopied ? (
                            <>
                                <span className={styles.tooltipLink}>
                                    {decodeURI(
                                        `https://${process.env.REACT_APP_REDIRECT_HOST}/${path}`
                                    )}
                                </span>
                                copied
                            </>
                        ) : (
                            'Copy to Clipboard'
                        )
                    }
                    aria-label="add"
                    interactive
                    open={tooltipVisible}
                >
                    <Button
                        className={styles.materialButton}
                        variant="text"
                        color="default"
                        onMouseEnter={() => setTooltipVisible(true)}
                        size="small"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                decodeURI(
                                    `https://${process.env.REACT_APP_REDIRECT_HOST}/${path}`
                                )
                            );
                            setLinkCopied(true);
                        }}
                        onMouseLeave={() => {
                            setLinkCopied(false);
                            setTooltipVisible(false);
                        }}
                    >
                        <FileCopyIcon className={styles.copyLinkButtonIcon} />
                    </Button>
                </DaznTooltip>{' '}
                <DaznTooltip title="Generate QR code">
                    <Button
                        className={styles.materialButton}
                        variant="text"
                        color="default"
                        onClick={() => setQrModalVisible(true)}
                        size="small"
                    >
                        <IconQrCode className={styles.qrCodeButtonIcon} />
                    </Button>
                </DaznTooltip>
                <QrModal
                    open={qrModalVisible}
                    setQrModalVisible={setQrModalVisible}
                    url={`https://${process.env.REACT_APP_REDIRECT_HOST}/${path}`}
                />
            </TableCell>
            <TableCell>{visits}</TableCell>
        </TableRow>
    );
};

export const LinkListRow = React.memo(Row);
