import React, { FunctionComponent, useEffect } from 'react';
import ReactGA from 'react-ga';
import {
    RmsFeedback,
    RmsButton,
} from '@dazn/dx-rate-my-service-components-react';
import { useAuth0 } from '@auth0/auth0-react';
import { Header } from './components/Header';
import { Content } from '@dazn/dx-components';
import { Route, Switch, useLocation } from 'react-router';
import { routes } from './pages/routes';
import {
    APP_NAME,
    RMS_ENV,
    RMS_SERVICE_NAME,
    ALLOWED_USERS,
} from './config/constants';

const App: FunctionComponent = () => {
    const location = useLocation();
    const { user, getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location]);

    if (
        user &&
        ALLOWED_USERS.length > 0 &&
        !ALLOWED_USERS.includes(user.email.toLowerCase())
    ) {
        return (
            <div style={{ margin: '20%', textAlign: 'center' }}>
                You are logged in as: {user.email}, but only explicitly allowed
                users can access this project. If you need to access this
                application, please reach out to the{' '}
                <a href="https://dazn.link/support/dx">DX team</a>.
            </div>
        );
    }

    return (
        <>
            <Header />
            <Content>
                <Switch>
                    {Object.values(routes).map((route, idx) => (
                        <Route
                            key={idx}
                            path={route.path}
                            component={route.component}
                            exact={true}
                        />
                    ))}
                </Switch>
                <RmsFeedback
                    displayName={APP_NAME}
                    serviceName={RMS_SERVICE_NAME}
                    endpointEnv={RMS_ENV}
                    author={user?.name || ''}
                    fetchToken={getAccessTokenSilently}
                >
                    <RmsButton />
                </RmsFeedback>
            </Content>
            <footer className="container mt-5 text-center">
                <p>
                    Please remember that this tool and links are only for
                    internal use
                </p>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Get in touch 🗣"
                            href="https://dazn.link/support/dx"
                        >
                            Get in touch
                            <span className="text-decoration-none d-inline-block ml-1">
                                🗣
                            </span>
                        </a>
                    </li>
                    <li className="list-inline-item">
                        <RmsFeedback
                            displayName={APP_NAME}
                            serviceName={RMS_SERVICE_NAME}
                            endpointEnv={RMS_ENV}
                            author={user?.name || ''}
                            fetchToken={getAccessTokenSilently}
                        >
                            <a href="/#">
                                Report an issue
                                <span className="text-decoration-none d-inline-block ml-1">
                                    🐛
                                </span>
                            </a>
                        </RmsFeedback>
                    </li>
                    <li className="list-inline-item">
                        <a
                            href={routes.logout.path}
                            data-test-id="logout-button"
                        >
                            Logout
                            <span className="text-decoration-none d-inline-block ml-1">
                                🚪
                            </span>
                        </a>
                    </li>
                </ul>
                Made with{' '}
                <span className="text-decoration-none d-inline-block">❤️</span>{' '}
                by{' '}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="PLATFORM 🤘"
                    href="https://livesport.atlassian.net/wiki/spaces/DP/overview"
                >
                    <b>PLAT</b>FORM
                    <span className="text-decoration-none d-inline-block ml-1">
                        🤘
                    </span>
                </a>
            </footer>
        </>
    );
};

export default App;
