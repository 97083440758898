import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { QRCode } from 'react-qrcode-logo';
import { DaznTheme } from '../../muiTheme';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

interface Props {
    open: boolean;
    url: string;
    setQrModalVisible: (copied: boolean) => void;
}

const useStyles = makeStyles((theme: DaznTheme) =>
    createStyles({
        qrModal: {
            position: 'absolute',
            width: 400,
            height: 400,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: 0,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    })
);

const RawQrModal: FunctionComponent<Props> = ({
    open,
    url,
    setQrModalVisible,
}: Props) => {
    const styles = useStyles();

    return (
        <Modal
            open={open}
            onClose={() => setQrModalVisible(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={styles.qrModal}>
                    <QRCode
                        size={400}
                        qrStyle="squares"
                        logoWidth={100}
                        quietZone={0}
                        logoImage="https://logo.daznplatform.com/DAZN.svg?fontColor=%23000000&backgroundColor=%23ffffff"
                        value={url}
                    />
                </div>
            </Fade>
        </Modal>
    );
};

export const QrModal = React.memo(RawQrModal);
