import React, { ComponentType, FC } from 'react';
import {
    withAuthenticationRequired,
    WithAuthenticationRequiredOptions,
} from '@auth0/auth0-react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';

const LoadingBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100vh',
});

const LoadingComponent = () => {
    return (
        <LoadingBox>
            <LinearProgress />
            <br />
            Authenticating...
        </LoadingBox>
    );
};

export const withAuth = (
    component: ComponentType,
    options?: WithAuthenticationRequiredOptions
): FC =>
    withAuthenticationRequired(component, {
        onRedirecting: LoadingComponent,
        ...options,
    });
