import React, { FunctionComponent, useEffect } from 'react';
import ReactGA from 'react-ga';
import _debounce from 'lodash/debounce';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useAuth0 } from '@auth0/auth0-react';

import { LinkListHead } from './LinkListHead';
import { LinkListRow } from './LinkListRow';
import { DaznLink } from '../../api/interfaces';
interface Props {
    isLoading: boolean;
    links: Array<DaznLink>;
    isActiveSearch: boolean;
    setIsActiveSearch: (isActiveSearch: boolean) => void;
}

export const LinkList: FunctionComponent<Props> = ({
    isLoading,
    links,
    isActiveSearch,
    setIsActiveSearch,
}: Props) => {
    const { user } = useAuth0();
    const { email: authorEmail } = user;

    const [linkCopied, setLinkCopied] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filterByCurrentUser, setFilterByCurrentUser] = React.useState(false);
    const lastSearchTerm = React.useRef(searchTerm);

    const [visibleLinks, setVisibleLinks] = React.useState(links);
    const debouncedVisibleLinksFilter = React.useRef(
        _debounce(
            (
                searchTerm: string,
                filterByCurrentUser: boolean,
                links: Array<DaznLink>
            ) => {
                if (searchTerm && searchTerm !== lastSearchTerm.current) {
                    lastSearchTerm.current = searchTerm;

                    ReactGA.event({
                        category: 'Link List',
                        action: 'filter link list',
                        label: `search_term: ${searchTerm}`,
                    });
                }

                setVisibleLinks(
                    links.filter((link) => {
                        if (!filterByCurrentUser) {
                            return link.id.includes(searchTerm);
                        }
                        return link.attributes.authorEmail
                            ? link.id.includes(searchTerm) &&
                                  link.attributes.authorEmail.includes(
                                      authorEmail
                                  )
                            : false;
                    })
                );
            },
            300
        )
    );

    useEffect(() => {
        debouncedVisibleLinksFilter.current(
            searchTerm,
            filterByCurrentUser,
            links
        );
    }, [searchTerm, filterByCurrentUser, links]);

    return (
        <>
            <TableContainer component={Paper}>
                {isLoading && <LinearProgress />}
                <Table aria-label="simple table">
                    <LinkListHead
                        isActiveSearch={isActiveSearch}
                        setIsActiveSearch={setIsActiveSearch}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        setFilterByCurrentUser={setFilterByCurrentUser}
                        filterByCurrentUser={filterByCurrentUser}
                    />

                    <TableBody>
                        {visibleLinks.map((link) => (
                            <LinkListRow
                                key={link.id}
                                link={link}
                                setLinkCopied={setLinkCopied}
                                linkCopied={linkCopied}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
