import React from 'react';

interface Props {
    className: string;
}

const IconQrCode: React.FunctionComponent<Props> = ({ className }: Props) => {
    return (
        <svg
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            width="401.994"
            height="401.994"
            x="0"
            y="0"
            enableBackground="new 0 0 401.994 401.994"
            version="1.1"
            viewBox="0 0 401.994 401.994"
            xmlSpace="preserve"
        >
            <path d="M0 401.991h182.724V219.265H0v182.726zm36.542-146.178h109.636v109.352H36.542V255.813z"></path>
            <path d="M73.089 292.355H109.633V328.904H73.089z"></path>
            <path d="M292.352 365.449H328.905V401.994H292.352z"></path>
            <path d="M365.442 365.449H401.994V401.994H365.442z"></path>
            <path d="M365.446 255.813L328.904 255.813 328.904 219.265 219.265 219.265 219.265 401.991 255.813 401.991 255.813 292.355 292.352 292.355 292.352 328.904 401.991 328.904 401.991 219.265 401.991 219.265 365.446 219.265z"></path>
            <path d="M0 182.728h182.724V0H0v182.728zM36.542 36.542h109.636v109.636H36.542V36.542z"></path>
            <path d="M73.089 73.089H109.633V109.636H73.089z"></path>
            <path d="M219.265 0v182.728h182.729V0H219.265zm146.181 146.178H255.813V36.542h109.633v109.636z"></path>
            <path d="M292.352 73.089H328.905V109.636H292.352z"></path>
        </svg>
    );
};

export default IconQrCode;
