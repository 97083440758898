import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { DaznTheme } from '../../muiTheme';

interface Props {
    authorEmail?: string;
    authorAvatar?: string;
}

const useStyles = makeStyles((theme: DaznTheme) =>
    createStyles({
        authorAvatar: {
            width: '25px',
            height: '25px',
            marginRight: '10px',
        },
        authorEmail: {
            color: theme.dazn.colors.tarmac.primary,
            fontWeight: 'bold',
            fontSize: '0.75rem',
        },
    })
);

export const CreatedBy: React.FunctionComponent<Props> = ({
    authorEmail,
    authorAvatar,
}: Props) => {
    const styles = useStyles();

    return (
        <>
            {authorAvatar && (
                <img
                    className={styles.authorAvatar}
                    src={authorAvatar}
                    alt=""
                />
            )}
            {authorEmail && (
                <span className={styles.authorEmail}>
                    {authorEmail.replace(/@.*$/, '')}
                </span>
            )}
        </>
    );
};
