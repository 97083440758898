import React from 'react';
import ReactDOM from 'react-dom';
import '@dazn/dx-components/styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { auth0Config } from './config/auth0';
import { Auth0Provider } from '@auth0/auth0-react';
import { createBrowserHistory } from 'history';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import ReactGA from 'react-ga';
import {
    CriticalErrorBoundary,
    ErrorCatcher,
} from './components/ErrorBoundary';
import { GlobalMessages } from './components/GlobalMessages';
import muiTheme from './muiTheme';
import { ThemeProvider } from '@material-ui/core';

ReactGA.initialize(process.env.REACT_APP_GA_ID as string);

const history = createBrowserHistory();

const onRedirectCallback = (appState: AppState) => {
    history.replace(appState?.returnTo ?? window.location.pathname);
};

ReactDOM.render(
    <CriticalErrorBoundary>
        <GlobalMessages>
            <ErrorCatcher />
            <Auth0Provider
                domain={auth0Config.domain}
                clientId={auth0Config.clientId}
                redirectUri={auth0Config.redirectUri}
                audience={auth0Config.audience}
                onRedirectCallback={onRedirectCallback}
                // This option is necessary for proper
                // authentication in Cypress tests:
                cacheLocation={'localstorage'}
            >
                <Router history={history}>
                    <ThemeProvider theme={muiTheme}>
                        <App />
                    </ThemeProvider>
                </Router>
            </Auth0Provider>
        </GlobalMessages>
    </CriticalErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
