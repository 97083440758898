import { ComponentType } from 'react';
import { AboutPage } from './AboutPage';
import { LogoutPage } from './LogoutPage';
import { DashboardPage } from './DashboardPage';

export interface Page {
    path: string;
    title: string;
    component: ComponentType;
}

export type PageName = 'dashboard' | 'logout' | 'about' | string;

const getRoutes = () => {
    const routes: Record<PageName, Page> = {
        dashboard: {
            path: '/',
            component: DashboardPage,
            title: 'Dashboard',
        },
        about: {
            path: '/about',
            component: AboutPage,
            title: 'About',
        },
        logout: {
            path: '/logout',
            component: LogoutPage,
            title: 'Logout',
        },
    };

    return routes;
};

export const routes: Record<PageName, Page> = getRoutes();
