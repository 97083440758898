import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

interface Props {
    open: boolean;
    setOpen: (open: boolean) => void;
    type: 'success' | 'error';
}

export const FormNotifications = ({ type, open, setOpen }: Props) => (
    <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
            setOpen(false);
        }}
    >
        <MuiAlert
            elevation={6}
            variant="filled"
            onClose={() => {
                setOpen(false);
            }}
            severity={type}
        >
            {type === 'success'
                ? `${process.env.REACT_APP_REDIRECT_HOST} created! It's in your clipboard.`
                : 'something went wrong'}
        </MuiAlert>
    </Snackbar>
);
