import React, { useEffect, FC } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import logoutImage from './logout.jpg';

export const LogoutPage: FC = () => {
    const { isAuthenticated, logout } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) logout();
    }, [isAuthenticated, logout]);

    return (
        <>
            <img src={logoutImage} alt="You've just successfully logged out" />
        </>
    );
};
