import { useAuth0 } from '@auth0/auth0-react';
import { propagateError } from '../components/ErrorBoundary/propagateError';

interface RequestOptions {
    baseUrl?: string;
}

export const useRequest = <T>({
    baseUrl = process.env.REACT_APP_API_HOST,
}: RequestOptions = {}) => {
    const { getAccessTokenSilently } = useAuth0();

    return (path: string, options?: RequestInit): Promise<T> =>
        getAccessTokenSilently()
            .then((token) =>
                fetch(`${baseUrl}${path}`, {
                    ...options,
                    headers: {
                        Authorization: `Bearer ${token}`,
                        ...options?.headers,
                    },
                })
            )
            .then((response) => response.json() as Promise<T>)
            .catch((e) => {
                propagateError(e);
                throw e;
            });
};
