import React, { FunctionComponent } from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import logoYellow from '../../assets/images/dazn.link-yellow.png';
import logoRed from '../../assets/images/dazn.link-red.png';
import logoBlue from '../../assets/images/dazn.link.png';
import { NavLink } from 'react-router-dom';
import { routes } from '../../pages/routes';
import { APP_NAME } from '../../config/constants';

const navRoutes = [routes.dashboard, routes.about];

export const Header: FunctionComponent = () => {
    const [isMouseOverLogo, setIsMouseOverLogo] = React.useState(false);

    return (
        <Navbar variant={'dark'} bg={'dark'} expand={'lg'}>
            <Navbar.Brand
                href="/"
                onMouseLeave={() => {
                    setIsMouseOverLogo(false);
                }}
                onMouseEnter={() => {
                    setIsMouseOverLogo(true);
                }}
            >
                <img
                    src={
                        isMouseOverLogo
                            ? Math.floor(Math.random() * 2) > 0
                                ? logoBlue
                                : logoRed
                            : logoYellow
                    }
                    alt="Logo"
                    className="navbar-brand-logo"
                />
                <span className="navbar-brand-title">{APP_NAME}</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {navRoutes.map((route, idx) => (
                        <NavLink
                            key={idx}
                            to={route.path}
                            className="nav-link"
                            activeClassName="active"
                            exact={true}
                        >
                            {route.title}
                        </NavLink>
                    ))}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
