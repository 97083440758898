import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import styles from './GlobalMessages.module.scss';
import { Toast } from 'react-bootstrap';
import { GlobalMessagesContext, Message } from './GlobalMessagesContext';
import { uniqueId } from 'lodash-es';

interface GlobalMessage extends Message {
    id: string;
}

type SetGlobalMessage = (
    value: ((prevState: GlobalMessage[]) => GlobalMessage[]) | GlobalMessage[]
) => void;

const buildTriggerMessage = (setMessages: SetGlobalMessage) => (
    message: Message
) => {
    setMessages((messages) => [
        ...messages,
        {
            ...message,
            id: uniqueId('globalMessage'),
        },
    ]);
};

const buildCloseMessage = (
    setMessages: SetGlobalMessage,
    messageId: string
) => () => {
    setMessages((messages) =>
        messages.filter((message) => message.id !== messageId)
    );
};

interface Props {}

export const GlobalMessages: FunctionComponent<Props> = ({
    children,
}: PropsWithChildren<Props>) => {
    const [messages, setMessages] = useState<GlobalMessage[]>([]);

    const triggerMessage = buildTriggerMessage(setMessages);

    return (
        <GlobalMessagesContext.Provider value={{ triggerMessage }}>
            {messages.length > 0 && (
                <div className={styles.container}>
                    {messages.map((message) => (
                        <Toast
                            key={message.id}
                            className={styles.toast}
                            onClose={buildCloseMessage(setMessages, message.id)}
                        >
                            <Toast.Header>
                                <strong className="mr-auto">
                                    {message.title}
                                </strong>
                            </Toast.Header>
                            <Toast.Body>{message.body}</Toast.Body>
                        </Toast>
                    ))}
                </div>
            )}
            {children}
        </GlobalMessagesContext.Provider>
    );
};
