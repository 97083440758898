import React, { FunctionComponent, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import deepEql from 'fast-deep-equal';
import _sortBy from 'lodash/sortBy';

import { CreateLinkForm } from '../../components/CreateLinkForm';
import { LinkList } from '../../components/LinkList';
import { useReadLinksAPI } from '../../api/useReadLinksAPI';
import { withAuth } from '../../utils/withAuth';

import { DaznLink } from '../../api/interfaces';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { APP_NAME } from '../../config/constants';

export const DataTestIds = {
    dashboardPage: 'DASHBOARD-PAGE',
};

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            margin: theme.spacing(6, 0, 2),
        },
    })
);

const DashboardPageComponent: FunctionComponent = () => {
    const request = useReadLinksAPI();

    const [isLoading, setIsLoading] = React.useState(true);
    const [linksData, setLinksData] = React.useState<Array<DaznLink>>([]);
    const [isActiveSearch, setIsActiveSearch] = React.useState(false);

    const styles = useStyles();

    useEffect(() => {
        if (!linksData.length) {
            const dataRaw = localStorage.getItem(
                `${process.env.REACT_APP_NAME}.links`
            );
            if (dataRaw) {
                setLinksData(JSON.parse(dataRaw));
            }
        }

        request().then(({ data }) => {
            const sortedLinksData = _sortBy(data, 'id');

            if (!deepEql(sortedLinksData, linksData)) {
                setLinksData(sortedLinksData);

                localStorage.setItem(
                    `${APP_NAME}.links`,
                    JSON.stringify(sortedLinksData)
                );
            }

            setIsLoading(false);
        });
        // we provide empty deps array to load data only once at the first page load
        // eslint-disable-next-line
    }, []);

    /**
     * Add the @param link to the linksData or update it if it already exists.
     */
    const updateLinksList = (link: DaznLink) => {
        const alreadyExistingIndex = linksData.findIndex(
            (oldLink) => oldLink.id === link.id
        );
        if (alreadyExistingIndex > -1) {
            setLinksData([
                ...linksData.slice(0, alreadyExistingIndex),
                link,
                ...linksData.slice(alreadyExistingIndex + 1),
            ]);
        } else {
            setLinksData([...linksData, link]);
        }
    };

    return (
        <div data-test-id={DataTestIds.dashboardPage}>
            <h1>POST /{process.env.REACT_APP_NAME}</h1>
            <CreateLinkForm
                linksData={linksData}
                updateLinksList={updateLinksList}
            />

            <h1 className={styles.header}>GET /{process.env.REACT_APP_NAME}</h1>
            {!linksData.length ? (
                <>
                    <CircularProgress />
                </>
            ) : (
                <LinkList
                    isLoading={isLoading}
                    isActiveSearch={isActiveSearch}
                    setIsActiveSearch={setIsActiveSearch}
                    links={_sortBy(linksData, 'id')}
                />
            )}
        </div>
    );
};

export const DashboardPage = withAuth(DashboardPageComponent);
