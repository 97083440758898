import { JsonResponse, DaznLink } from './interfaces';
import { useRequest } from './useRequest';

export interface CreateLinkAPIArgs {
    path: string;
    url: string;
}

export const useCreateLinksAPI = () => {
    const request = useRequest<JsonResponse<Array<DaznLink>>>();

    return ({ path, url }: CreateLinkAPIArgs) =>
        request('/link', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: {
                    attributes: {
                        path: encodeURI(path),
                        url: decodeURI(url) === url ? encodeURI(url) : url,
                    },
                },
            }),
        });
};
