import { FunctionComponent, useContext, useEffect } from 'react';
import { isObject } from 'lodash-es';
import { AppError, getErrorMessage } from './errorMessage';
import { GlobalMessagesContext } from '../GlobalMessages';

interface HandledError extends Error {
    handled?: boolean;
}

const isHandledError = (error: AppError): error is HandledError =>
    isObject(error);

export const ErrorCatcher: FunctionComponent = () => {
    const { triggerMessage } = useContext(GlobalMessagesContext);

    useEffect(() => {
        const errorEventListener = (event: ErrorEvent) => {
            const error: AppError = event.error;

            if (isHandledError(error) && error.handled) {
                return true;
            }

            triggerMessage({
                title: 'Application Error',
                body: `An unhandled error "${getErrorMessage(
                    error
                )}" occurred.`,
            });

            if (isHandledError(error)) {
                error.handled = true;
            }
        };

        window.addEventListener('error', errorEventListener);

        return () => {
            window.removeEventListener('error', errorEventListener);
        };
    }, [triggerMessage]);

    return null;
};
