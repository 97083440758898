import React, { Component } from 'react';
import { AppError, getErrorMessage } from './errorMessage';
import styles from './CriticalErrorBoundary.module.scss';

interface Props {}

interface State {
    error: AppError | false;
}

export class CriticalErrorBoundary extends Component<Props, State> {
    public constructor(props: Props) {
        super(props);

        this.state = {
            error: false,
        };
    }

    public componentDidCatch(error: Error) {
        this.setState({
            error: error,
        });
    }

    public render() {
        if (this.state.error !== false) {
            return (
                <div className={styles.container}>
                    <h1 className={styles.header}>Our apologies</h1>
                    <span className={styles.text}>
                        The app has experienced an unexpected error:{' '}
                        <strong>{getErrorMessage(this.state.error)}</strong>.
                    </span>
                    <span className={styles.text}>
                        Contact the{' '}
                        <a
                            href="https://teams.microsoft.com/l/channel/19%3ab7f22a2544c243438ceb78df8bfc6c34%40thread.skype/DX?groupId=602f2603-465a-49fa-be9e-abfe0b05b551&tenantId=30459df5-1e53-4d8b-a162-0ad2348546f1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            DX team
                        </a>{' '}
                        to get support.
                    </span>
                </div>
            );
        }

        return this.props.children;
    }
}
