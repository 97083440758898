import { createContext } from 'react';

export interface Message {
    title: string;
    body: string;
}

interface GlobalMessagesContextParams {
    triggerMessage: (message: Message) => void;
}

export const GlobalMessagesContext = createContext<GlobalMessagesContextParams>(
    {
        triggerMessage: () =>
            console.error(
                'Trigger message function must be set in GlobalMessages component'
            ),
    }
);
