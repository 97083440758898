export type AppError = Error | string | undefined | null;

export const getErrorMessage = (error: AppError): string => {
    if (!error) {
        return 'Undefined error';
    }

    if (typeof error === 'string') {
        return error;
    }

    return error.message;
};
