import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import FilterListIcon from '@material-ui/icons/FilterList';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Tooltip from '@material-ui/core/Tooltip';
interface Props {
    isActiveSearch: boolean;
    setIsActiveSearch: (isActiveSearch: boolean) => void;
    setSearchTerm: (searchTerm: string) => void;
    searchTerm: string;
    setFilterByCurrentUser: (filterByCurrentUser: boolean) => void;
    filterByCurrentUser: boolean;
}

export const DataTestIds = {
    searchIconButton: 'SEARCH-ICON-BUTTON',
    searchInput: 'SEARCH-INPUT',
};

const useStyles = makeStyles(
    createStyles({
        daznLinkHeader: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        redirectToHeader: {
            width: '40%',
        },
        createdByHeader: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        materialButton: {
            minWidth: '0',
            padding: '6px',
            borderWidth: '0',
            outline: 'none !important',
        },
    })
);

const DaznTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        boxShadow: theme.shadows[1],
        fontSize: '0.75rem',
        padding: theme.spacing(),
    },
}))(Tooltip);

const Head: FunctionComponent<Props> = ({
    isActiveSearch,
    setIsActiveSearch,
    setSearchTerm,
    searchTerm,
    setFilterByCurrentUser,
    filterByCurrentUser,
}: Props) => {
    const styles = useStyles();
    const [selected, setSelected] = React.useState(false);

    return (
        <TableHead>
            <TableRow>
                <TableCell className={styles.daznLinkHeader}>
                    {!isActiveSearch ? (
                        <>
                            <h4>short_name:</h4>
                            <Button
                                onClick={() => setIsActiveSearch(true)}
                                data-test-id={DataTestIds.searchIconButton}
                            >
                                <SearchIcon />
                            </Button>
                        </>
                    ) : (
                        <>
                            <TextField
                                value={searchTerm}
                                onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                    setSearchTerm(e.target.value);
                                }}
                                autoFocus
                                data-test-id={DataTestIds.searchInput}
                            />
                            <Button
                                onClick={() => {
                                    setIsActiveSearch(false);
                                    setSearchTerm('');
                                }}
                            >
                                <CancelIcon />
                            </Button>
                        </>
                    )}
                </TableCell>
                <TableCell className={styles.redirectToHeader}>
                    <h4>redirecting_to:</h4>
                </TableCell>
                <TableCell className={styles.createdByHeader}>
                    <h4>author:</h4>
                    <DaznTooltip title="See my links">
                        <ToggleButton
                            size="small"
                            selected={selected}
                            onChange={() => {
                                setSelected(!selected);
                            }}
                            onClick={() =>
                                setFilterByCurrentUser(!filterByCurrentUser)
                            }
                            className={styles.materialButton}
                        >
                            <FilterListIcon />
                        </ToggleButton>
                    </DaznTooltip>
                </TableCell>
                <TableCell>
                    <h4>actions:</h4>
                </TableCell>
                <TableCell>
                    <h4>visits:</h4>
                </TableCell>
            </TableRow>
        </TableHead>
    );
};

export const LinkListHead = React.memo(Head);
