import { JsonResponse, DaznLink } from './interfaces';
import { useRequest } from './useRequest';

export const useReadLinksAPI = () => {
    const request = useRequest<JsonResponse<DaznLink[]>>();

    return () => {
        return request('/links');
    };
};
