import createMuiTheme, {
    ThemeOptions,
    Theme,
} from '@material-ui/core/styles/createMuiTheme';

const daznThemeOptions = {
    dazn: {
        colors: {
            tarmac: {
                primary: '#0c161c',
                light10: '#242d33',
                light20: '#3d4549',
                light40: '#6d7377',
                light60: '#9ea2a4',
                light70: '#b6b9bb',
                light80: '#ced0d2',
            },
            yellow: {
                primary: '#f7ff1a',
                dark: '#dee517',
                light: '#fbff8c',
            },
            blue: {
                primary: '#0070ba',
                dark: '#005994',
            },
        },
    },
};

const themeOptions: ThemeOptions & typeof daznThemeOptions = {
    palette: {
        type: 'light',
        primary: {
            main: '#0000EE',
        },
        secondary: {
            main: '#551A8B',
        },
        error: {
            main: '#ab2300',
        },
        success: {
            main: '#129600',
        },
        info: {
            main: '#f1f8fd',
            contrastText: 'rgba(216,9,9,0.87)',
        },
        divider: '#0C161C',
        warning: {
            main: '#FD7777',
        },
        text: {
            secondary: '#242d33',
            disabled: '#6D7377',
            primary: '#0C161C',
        },
        background: {
            default: '#ffffff',
        },
    },
    typography: {
        fontFamily: '"DAZNOscine", Helvetica, sans-serif',
    },
    ...daznThemeOptions,
};

export type DaznTheme = Theme & typeof daznThemeOptions;

export default createMuiTheme(themeOptions);
