import React, { FunctionComponent } from 'react';
import { withAuth } from '../../utils/withAuth';
import { Title } from '@dazn/dx-components';

const AboutPageComponent: FunctionComponent = () => {
    return (
        <>
            <Title>About</Title>

            <p>
                🔗 <em>dazn.link</em> is a DAZN branded URL shortener.
            </p>

            <h2>What is it for?</h2>
            <p>
                It&apos;s an internal tool that allows DAZN engineers to quickly
                create and share memorable URLs.
            </p>
            <p>
                You can use the links in presentations if you want your audience
                to type or remember the URL after the meeting. If you often send
                links of Confluence pages to other teams for instance, you could
                create links you remember so that you don&apos; have to find
                them each time.
            </p>

            <h2>Can I use this externally?</h2>
            <p>
                <strong>No</strong>, the tool should be used only for DAZN
                related links. Although DX helped with development, this
                initiative is an octo project which means that it&apos;s not
                intended to be used at scale or that DX will guarantee support
                for it.
            </p>

            <h2>Can I contribute?</h2>
            <p>
                <strong>Yes</strong>, get in touch with DX or open a pull
                request on GitHub.
            </p>

            <p>
                More information is available on GitHub. Web app:{' '}
                <a
                    href="https://github.com/getndazn/dazn.link-ui"
                    target="_blank"
                    rel="noreferrer"
                >
                    dazn.link-ui
                </a>{' '}
                and for the API:{' '}
                <a
                    href="https://github.com/getndazn/dazn.link"
                    target="_blank"
                    rel="noreferrer"
                >
                    dazn.link-ui
                </a>
                .
            </p>
        </>
    );
};

export const AboutPage = withAuth(AboutPageComponent);
