import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import ReactGA from 'react-ga';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Telegram';
import InputAdornment from '@material-ui/core/InputAdornment';
import { styled, makeStyles, createStyles } from '@material-ui/core/styles';
import isURL from 'validator/es/lib/isURL';

import { FormNotifications } from './FormNotifications';
import { useCreateLinksAPI } from '../../api/useCreateLinkAPI';
import { DaznTheme } from '../../muiTheme';
import { DaznLink } from '../../api/interfaces';

export const DataTestIds = {
    daznLinkPathInput: 'DAZN-LINK-PATH-INPUT',
    redirectToInput: 'REDIRECT-TO-INPUT',
    submitLinkButton: 'SUBMIT-LINK-BUTTON',
};

const useStyles = makeStyles((theme) =>
    createStyles({
        textInput: {
            margin: theme.spacing(1.2, 0),
        },
        daznLinkInput: {
            width: '60%',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        daznLinkAdornment: {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
            opacity: 0.5,
        },
        redirectToInput: {
            width: '60%',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        createIcon: {
            marginRight: theme.spacing(1),
        },
        linkPreview: {
            maxWidth: '80%',
            opacity: (props: { daznLinkPath: string }) =>
                props.daznLinkPath.length ? 1 : 0.05,
            marginBottom: theme.spacing(2),
            lineHeight: 2,

            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%',
            },
        },
        linkPreviewUrl: {
            color: theme.palette.primary.main,
            wordBreak: 'break-all',
        },
    })
);

const SubmitContainer = styled(Box)(({ theme }: { theme: DaznTheme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
    width: '140px',
}));

interface Props {
    updateLinksList: (link: DaznLink) => void;
    linksData: DaznLink[];
}

export const CreateLinkForm: FunctionComponent<Props> = ({
    updateLinksList,
    linksData,
}: Props) => {
    const [createRequestPending, setCreateRequestPending] = React.useState(
        false
    );
    const [daznLinkPath, setDaznLinkPath] = React.useState('');
    const [redirectToUrl, setRedirectToUrl] = React.useState<string>('');
    const [showNotification, setShowNotification] = React.useState(false);
    const styles = useStyles({ daznLinkPath });
    const submit = useCreateLinksAPI();

    const redirectionURLError = Boolean(
        redirectToUrl.length &&
            !isURL(redirectToUrl, { require_protocol: true })
    );

    const canSubmit = Boolean(
        redirectToUrl.length && !redirectionURLError && daznLinkPath.length
    );

    const isLinkTaken = linksData.find(
        (link) => link.attributes.path === daznLinkPath
    );

    const getHelperText = () => {
        let text = <p>Nested paths are supported (e.g. /plat/dx)</p>;

        if (daznLinkPath) {
            text = (
                <p>
                    Preview: {process.env.REACT_APP_REDIRECT_HOST}/
                    {encodeURI(daznLinkPath)}
                </p>
            );

            if (isLinkTaken) {
                text = (
                    <p>
                        Preview: {process.env.REACT_APP_REDIRECT_HOST}/
                        {encodeURI(daznLinkPath)} -{' '}
                        <span className="text-warning">
                            This link is already taken. If you proceed the link
                            will be updated.
                        </span>
                    </p>
                );
            } else {
                text = (
                    <p>
                        Preview: {process.env.REACT_APP_REDIRECT_HOST}/
                        {encodeURI(daznLinkPath)}
                    </p>
                );
            }
        }

        return text;
    };

    return (
        <Box>
            <Box>
                <TextField
                    variant="outlined"
                    className={clsx(styles.daznLinkInput, styles.textInput)}
                    value={daznLinkPath}
                    multiline
                    label={`short_name:`}
                    required
                    disabled={createRequestPending}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setDaznLinkPath(
                            daznLinkPath[0] === '/'
                                ? event.target.value.substr(1)
                                : event.target.value
                        );
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <span className={styles.daznLinkAdornment}>
                                    {`https://${process.env.REACT_APP_REDIRECT_HOST}/`}
                                </span>
                            </InputAdornment>
                        ),
                    }}
                    helperText={getHelperText()}
                    data-test-id={DataTestIds.daznLinkPathInput}
                />

                <TextField
                    className={clsx(styles.redirectToInput, styles.textInput)}
                    label="redirecting_to:"
                    multiline
                    variant="outlined"
                    required
                    disabled={createRequestPending}
                    error={redirectionURLError}
                    helperText={
                        redirectionURLError
                            ? 'Please provide a valid redirection URL.'
                            : 'Enter the full URL here. The short link will redirect to this URL after saving.'
                    }
                    value={redirectToUrl}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setRedirectToUrl(event.target.value);
                    }}
                    data-test-id={DataTestIds.redirectToInput}
                />
            </Box>
            <SubmitContainer>
                <Fab
                    color="secondary"
                    variant="extended"
                    disabled={!canSubmit || createRequestPending}
                    aria-label="add"
                    onClick={async () => {
                        ReactGA.event({
                            category: 'Link Form',
                            action: isLinkTaken ? 'update' : 'create',
                            label: `${daznLinkPath} -> ${redirectToUrl}`,
                        });

                        setCreateRequestPending(true);
                        const {
                            data: [newLink],
                        } = await submit({
                            path: daznLinkPath,
                            url: redirectToUrl,
                        });

                        setShowNotification(true);
                        setCreateRequestPending(false);

                        navigator.clipboard.writeText(
                            decodeURI(
                                `https://${process.env.REACT_APP_REDIRECT_HOST}/${daznLinkPath}`
                            )
                        );
                        setDaznLinkPath('');
                        setRedirectToUrl('');

                        updateLinksList(newLink);
                    }}
                    data-test-id={DataTestIds.submitLinkButton}
                >
                    {!createRequestPending ? (
                        <>
                            <SaveIcon className={styles.createIcon} />
                            {isLinkTaken ? 'update' : 'create'}
                        </>
                    ) : (
                        <>
                            <CircularProgress size={30} />
                        </>
                    )}
                </Fab>
            </SubmitContainer>
            <FormNotifications
                open={showNotification}
                setOpen={setShowNotification}
                type="success"
            />
        </Box>
    );
};
